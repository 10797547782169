<template>
  <div class="gext">
    <div style="
          border-bottom: 1px solid #8a8787;
          margin-right: 3rem;
          margin-left: 3rem;
        "></div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="7">
          <div class="result">
            <h2 style="font-weight: 400">
              Tu información a sido procesada<br />
              <span :style="`font-weight: 900;`">correctamente</span>
            </h2>
          </div>
        </v-col>
        <v-col style="display: flex; justify-content: center">
          <Success class="max-width-500" :color="color" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Success from "@/components/Success.vue";

export default {
  components: {
    Success,
  },
  data() {
    return {
      color: null,
      serviceName: null,
      pdf: null,
    };
  },
  beforeMount() {
    this.color = "#eb345e";
    this.serviceName = "Garantia Extendida";
    this.pdf = "certificado_test.pdf";
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.result {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.bg-image {
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 0;
}

@media (max-width: 800px) {
  .bg-image {
    top: 250px;
  }
}

@media (max-width: 530px) {
  .bg-image {
    left: 0;
  }
}
</style>